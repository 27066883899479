input[type="range"] {
    -webkit-appearance: none;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100px;
    height: 2px;
    background: #fff;
    border: none;
    border-radius: 3px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 5px;
    width: 10%;
    border-radius: 0%;
    background: #fff;
    margin-top: -4px;
  }
  
  input[type="range"]:focus {
    outline: none;
  }
  
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #fff;
  }
  .carousel-container-with-scrollbar {
    padding-left: 0px;
    padding-bottom: 20px;
    overflow: visible !important;
  }
  .custom-slider {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
  }
  .custom-slider__input {
    width: 100%;
    background: #363B40;
    
  }
  .slider-image-item {
    padding: 20px 0 20px 20px;
  }
  